import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {FormRadio, FormFileInput, FormInput, FormSelect} from '../Forms';
import {TITLE_AREA_MAPPING} from '../../helpers/constants';

const JobApplicationForm = ({sendForm, initialApplicationValues, vacancyCode, jobsForVacancyType, isApprenticeship}) => {
  const {i18n, t} = useTranslation();
  const [areaOptions, setAreaOptions] = useState([]);
  const [vacancyOptions, setVacancyOptions] = useState([]);
  const [isLoading, setIsDisabled] = useState(false);
  const [formValues, setFormValues] = useState({
    FirstName: '',
    LastName: '',
    Email: '',
    Phone: '',
    BirthDate: '',
    Address: {
      Street: '',
      Number: '',
      Box: '',
      PostalCode: '',
      Town: '',
    },
    GeographicArea: '',
    FilesCV: [],
    FilesMotivationLetter: [],
    Privacy: false,
    VacancyCode: vacancyCode,
  });

  useEffect(() => {
    if (!initialApplicationValues.jobCountries || !initialApplicationValues.jobGeographicAreas) return;

    const areaOptions = initialApplicationValues.jobGeographicAreas
        .map((a) => ({
          label: a[TITLE_AREA_MAPPING[i18n.language] ?? TITLE_AREA_MAPPING['fr']],
          value: a.value,
        }))
        .filter((option, index, self) =>
          index === self.findIndex((o) => o.value === option.value),
        )
        .sort((a, b) => a.label.localeCompare(b.label));

    setAreaOptions(areaOptions);
  }, [initialApplicationValues]);

  useEffect(() => {
    if (!jobsForVacancyType) return;

    const vacancyOptions = jobsForVacancyType.map((job) => ({
      label: `${job.type} : ${job.title}`,
      value: job.code,
    })).sort((a, b) => a.label.localeCompare(b.label));

    setVacancyOptions(vacancyOptions);
  }, [jobsForVacancyType]);

  const validateFormValues = () => {
    const requiredFields = [
      'FirstName', 'LastName', 'Email', 'Phone', 'BirthDate', 'GeographicArea',
      'FilesCV', 'FilesMotivationLetter', 'Privacy', 'VacancyCode',
      'Address.Street', 'Address.Number', 'Address.PostalCode', 'Address.Town',
    ];

    for (const field of requiredFields) {
      const keys = field.split('.');
      let value = formValues;
      for (const key of keys) {
        value = value[key];
        if (value === undefined) break;
      }
      if (!value || (Array.isArray(value) && value.length === 0)) {
        console.error('Job application form error.', `Field ${field} is required.`);
        return false;
      }
    }

    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateFormValues()) return;
    setIsDisabled(true);
    sendForm(formValues);
  };

  // Change handler for form inputs
  const handleChange = (value, name) => {
    const keys = name.split('.');
    if (keys.length > 1) {
      setFormValues((prevValues) => {
        const updatedValues = {...prevValues};
        let nestedObject = updatedValues;
        for (let i = 0; i < keys.length - 1; i++) {
          nestedObject = nestedObject[keys[i]];
        }
        nestedObject[keys[keys.length - 1]] = value;
        return updatedValues;
      });
    } else {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }
  };

  const handleInputChange = (e) => {
    const {value, name} = e.target;
    handleChange(value, name);
  };

  const handleSelectChange = (selectedOption, {name}, useLabelAsValue = false) => {
    handleChange(selectedOption[useLabelAsValue ? 'label' : 'value'], name);
  };

  const handleFileChange = (e) => {
    const {name, files} = e.target;
    handleChange(files[0], name);
  };

  const handleRadioChange = (e) => {
    const {name, checked} = e.target;
    handleChange(checked, name);
  };

  return (
    <form className='job-form' onSubmit={handleSubmit} isDisabled={isLoading}>
      <div className='job-form__summary'></div>
      <fieldset className='job-form__about-me'>
        <legend>{t('About me')}</legend>
        <FormInput name='FirstName' type="text" maxlength="50" label={t('First name')} autoComplete="given-name" required onChange={(e) => handleInputChange(e)} disabled={isLoading} />
        <FormInput name='LastName' type="text" maxlength="50" label={t('Last name')} autoComplete="family-name" required onChange={(e) => handleInputChange(e)} disabled={isLoading} />
        <FormInput name='Email' type="email" maxlength="100" label={t('Email address')} autoComplete="email" inputMode="email" required onChange={(e) => handleInputChange(e)} disabled={isLoading} />
        <FormInput name='Phone' type="tel" maxlength="30" label={t('Telephone number')} autoComplete="tel" inputMode="tel" required onChange={(e) => handleInputChange(e)} disabled={isLoading} />
        <FormInput name='BirthDate' type="date" label={t('Birth date')} autoComplete="bday" required onChange={(e) => handleInputChange(e)} disabled={isLoading} />
        <FormSelect name='GeographicArea' label={t('Geographical area')} options={areaOptions} autoComplete="address-level1" isSearchable={true} required isDisabled={isLoading}
          onChange={(selectedOption) => handleSelectChange(selectedOption, {name: 'GeographicArea'})} />
        <FormInput name='Address.PostalCode' type="text" maxlength="20" label={t('Postal code')} autoComplete="postal-code" required isDisabled={isLoading}
          defaultValue={formValues.Address.PostalCode}
          onChange={(e) => handleInputChange(e) }/>
        <FormInput name='Address.Town' type='text' maxlength="50" label={t('Location')} autoComplete='address-level2' required isDisabled={isLoading}
          onChange={(e) => handleInputChange(e)} />
        <div className='job-form__address'>
          <FormInput name='Address.Street' type="text" maxlength="255" label={t('Street name')} autoComplete="address-line1" required onChange={(e) => handleInputChange(e)} disabled={isLoading} />
          <FormInput name='Address.Number' type="text" maxlength="10" label={t('Street number')} autoComplete="address-line2" required onChange={(e) => handleInputChange(e)} disabled={isLoading} />
          <FormInput name='Address.Box' type="text" maxlength="15" label={t('Mailbox')} autoComplete="address-line3" onChange={(e) => handleInputChange(e)} disabled={isLoading} />
        </div>
        <FormSelect name='VacancyCode' label={t('Desired occupation')} options={vacancyOptions} isSearchable={true} required isDisabled={vacancyCode !== undefined || isLoading}
          defaultValue={vacancyCode}
          onChange={(selectedOption) => handleSelectChange(selectedOption, {name: 'VacancyCode'})} />
      </fieldset>
      { isApprenticeship ? (
        <fieldset className='job-form__stage'>
          <legend>{t('My internship/apprenticeship')}</legend>
          <FormInput name='School' type='text' maxlength="100" label={t('School')} required onChange={(e) => handleInputChange(e)} disabled={isLoading} />
          <FormInput name='InternshipDuration' type='number' maxlength="50" label={t('Duration of my internship/apprenticeship (in weeks)')} inputMode="numeric" required onChange={(e) => handleInputChange(e)} disabled={isLoading} />
        </fieldset>
      ) : null}
      <fieldset className='job-form__my-documents'>
        <legend>{t('My documents')}</legend>
        <FormFileInput name='FilesCV' type="file" accept=".pdf, .doc, .docx, .jpg, .png" label={t('My CV')} required
          onChange={(e) => handleFileChange(e)} disabled={isLoading} />
        <FormFileInput name='FilesMotivationLetter' type="file" accept=".pdf, .doc, .docx, .jpg, .png" label={t('My motivation letter')} required
          onChange={(e) => handleFileChange(e)} disabled={isLoading} />
      </fieldset>
      <fieldset className='job-form__privacy'>
        <legend>{t('Respecting my privacy')}</legend>
        <FormRadio name='Privacy' label={t('I accept Thomas & Piron\'s terms and conditions regarding the processing of my personal data.')}
          required
          labelLink={t('Find out more about these conditions.')}
          onChange={(e) => handleRadioChange(e)} disabled={isLoading} />
      </fieldset>
      <button className='job-form__submit tp-btn--filled' type='submit' disabled={isLoading}>{t('Send application')}</button>
      { isLoading && <p className='job-form__loading'>{t('Loading')} ...</p> }
    </form>
  );
};

JobApplicationForm.propTypes = {
  sendForm: PropTypes.func.isRequired,
  initialApplicationValues: PropTypes.object.isRequired,
  vacancyCode: PropTypes.string,
  jobsForVacancyType: PropTypes.array,
  isApprenticeship: PropTypes.bool,
};

JobApplicationForm.defaultProps = {
  isApprenticeship: false,
};

export default JobApplicationForm;
